<template>
  <v-data-table
    v-model="arTaxTypeList"
    :headers="headers"
    :items="taxTypes"
    :loading="loading"
    class="dt-listing-simple"
    disable-pagination
    disable-sort
    hide-default-footer
    show-select
    @item-selected="onToggle"
  >
    <template #[`header.data-table-select`]>
      <span />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins, VModel } from "vue-property-decorator";
import { filter, map } from "lodash";
import { TaxTypeModule } from "@/store/taxtype";
import {
  Company,
  CompanySettings,
  CompanySettingsData,
  TaxTypeData,
} from "@planetadeleste/vue-mc-gw";
import DataTableMixin, {
  DataTableHeader,
  DataTableSelect,
} from "@/mixins/DataTableMixin";

@Component
export default class TaxTypes extends Mixins(DataTableMixin) {
  @VModel({ type: Object, default: () => new Company() }) company!: Company;

  loading = false;
  arTaxTypeList: Partial<TaxTypeData>[] = [];
  headers: DataTableHeader[] = [
    { text: "name", value: "name", sortOrder: 1 },
    { text: "description", value: "description" },
    { text: "code", value: "code" },
    { text: "value", value: "percent" },
  ];

  get companySettings() {
    const obCompanySettings = this.company?.get(
      "settings",
      {}
    ) as CompanySettingsData;

    return new CompanySettings(obCompanySettings);
  }

  get taxTypes() {
    return TaxTypeModule.items;
  }

  get idList(): number[] {
    return this.companySettings.get("tax_types", []) as number[];
  }

  async onToggle(obValue: DataTableSelect<TaxTypeData>) {
    const bSelected = obValue.value;
    const obItem = obValue.item;
    const arIdList = map(this.arTaxTypeList, "id") as number[];

    if (bSelected) {
      arIdList.push(obItem.id);
    } else {
      const idx = arIdList.indexOf(obItem.id);
      if (idx >= 0) {
        arIdList.splice(idx, 1);
      }
    }

    this.loading = true;
    const obResponse = await this.company.addUpdateTaxTypes(arIdList);
    const arTaxTypeList = obResponse.response
      ? obResponse.response.data.data
      : [];

    if (arTaxTypeList) {
      this.companySettings.set("tax_types", map(arTaxTypeList, "id"));
    }

    this.loading = false;
  }

  async mounted() {
    this.mapDTHeaders();

    if (!this.taxTypes.length) {
      await TaxTypeModule.load();
    }

    this.arTaxTypeList = filter(this.taxTypes, (obItem) =>
      this.idList.includes(obItem.id as number)
    );
  }
}
</script>
